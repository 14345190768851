import React, { useState, useEffect } from "react";
import { Row, Col, Jumbotron, DropdownItem } from 'reactstrap';
import { Link } from "gatsby";
import Layout from "../components/Layout";
import StepsComponent from '../components/StepsComponent/StepsComponent';
import RecentNews from '../components/RecentNews/RecentNews';
import Card from '../components/Card/Card';
import ConfirmationModal from '../components/Modals/ConfirmationModal/ConfirmationModal'
import Dropdown from "../components/Dropdown/Dropdown";
import DropdownSearch from "../components/DropdownSearch/DropdownSearch";
import SearchIcon from "@material-ui/icons/Search";
import BuildIcon from "@material-ui/icons/Build";
import ytongImage from '../assets/images/home-left-img.png';
import archImage from '../assets/images/home-right-img.png';
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import video0 from "../assets/videos/video0.webm";
import video1 from "../assets/videos/video1.webm";
import video2 from "../assets/videos/video2.webm";
import { urlDescription } from '../utils/pageDescription'
import "./styles.scss";

import {
  setServiceType,
  setCity,
  setDistrict,
  getCities,
  getDistricts,
  setBePartner,
  getLastNew,
  getLastArticle,
  getLastProject
} from '../reducers/Home/actions'
import { filterService } from "../reducers/Services/actions";
import { getArchitects, getBuilders } from '../reducers/Services/actions'
import { connect } from 'react-redux'
import { graphql } from 'gatsby';
import './styles.scss';

const HomePage = ({
  city,
  district,
  getCities,
  page,
  setServiceType,
  serviceType,
  partnerType,
  setCity,
  setDistrict,
  getDistricts,
  filterService,
  data,
  lastData,
  getLastNew,
  getLastArticle,
  getLastProject,
  setBePartner,
  bePartnerSuccess,
  bePartnerFailure
}) => {

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [counter, setCounter] = useState(0);
  const [place, setPlace] = useState(null)
  const [districtValue, setDistrictValue] = useState(null)

  const staticLastProject = data && data.lastProjects.edges;
  const staticLastNew = data && data.lastNews.edges;
  const staticLastArticle = data && data.lastArticles.edges;

  useEffect(() => {
    setServiceType({ type: '' })
    setCity(null)
    setDistrict(null)
  }, [setServiceType, setCity])

  useEffect(() => {
    getDistricts()
  }, [])

  useEffect(() => {
    if (serviceType && serviceType !== "" && districtValue) {
      filterService({
        page,
        districtId: district.id,
        service: serviceType
      });
      return
    }
    if (serviceType && serviceType !== "") {
      filterService({
        page,
        cityId: city && city.id,
        service: serviceType
      });
    }
  }, [filterService, page, serviceType, city]);

  useEffect(() => {
    getCities()
  }, [getCities])

  useEffect(() => {
    getLastNew({ type: 'news' })
    getLastArticle({ type: 'articles' })
    getLastProject({ type: 'projects' })
  }, [getLastNew, getLastArticle, getLastProject])

  const renderVideo = (data) => {
    switch (data) {
      case 1:
        return (
          <video className="video" autoPlay muted loop>
            <source src={video0} />
          </video>
        );
      case 2:
        return (
          <video className="video" autoPlay muted loop>
            <source src={video1} />
          </video>
        );
      case 3:
        return (
          <video className="video" autoPlay muted loop>
            <source src={video2} />
          </video>
        );
      default:
        return (
          <video className="video" autoPlay muted loop>
            <source src={video2} />
          </video>
        );
    }
  };

  const returnService = (href) => {
    switch (href) {
      case "architects":
        return "Архитектурно бюро";
      case "builders":
        return "Строителна компания";
      case "dealers":
        return "Дистрибутори";

      default:
        return "";
    }
  }

  const returnServiceUrl = (href) => {
    switch (href) {
      case "architects":
        return "?service=architects";
      case "builders":
        return "?service=builders";
      case "dealers":
        return "?service=dealers";

      default:
        return "";
    }
  };

  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className='home-page-wrapper'>
        <div className="nav-container1">{renderVideo(counter)}
          <div className="gradient" />
        </div>

        <Jumbotron className="nav-container2">
          <div className="center header-wrapper">
            <div className="home-page-title">
              <h1 className="header-title mt-4">
                Планирате строителство на къща?
              </h1>
              <p className="header-subtitle">
                Потърсете тук проектант, строител, доставчик! Разгледайте нашите избрани проекти!
              </p>
            </div>
            <section className="header-section d-flex align-items-center align-items-lg-end flex-column flex-lg-row">
              <Dropdown
                value={returnService(serviceType)}
                title="Видове услуги"
                description='(проектиране, изпълнение, продажби)'
                dropdownOpen={dropdownOpen}
                image={<BuildIcon />}
                text="Изберете партньор"
                borderClass="header-dropdown-border"
                options={
                  <>
                    <DropdownItem
                      className="cursor-pointer"
                      onClick={(e) => {
                        if (e.target.outerText === 'Архитектурно бюро') {
                          getArchitects({ page })
                        }
                        if (e.target.outerText === 'Строителна компания') {
                          getBuilders({ page: 1 })
                        }
                        setDropdownOpen(!dropdownOpen)
                        setServiceType({ type: e.target.outerText })
                      }}
                    >
                      Архитектурно бюро
                    </DropdownItem>
                    <DropdownItem
                      className="cursor-pointer"
                      onClick={(e) => {
                        if (e.target.outerText === 'Архитектурно бюро') {
                          getArchitects({ page })
                        }
                        if (e.target.outerText === 'Строителна компания') {
                          getBuilders({ page: 1 })
                        }
                        setDropdownOpen(!dropdownOpen)
                        setServiceType({ type: e.target.outerText })
                      }}
                    >
                      Строителна компания
                    </DropdownItem>
                    <DropdownItem
                      className="cursor-pointer"
                      onClick={(e) => {
                        if (e.target.outerText === 'Архитектурно бюро') {
                          getArchitects({ page })
                        }
                        if (e.target.outerText === 'Строителна компания') {
                          getBuilders({ page: 1 })
                        }
                        setDropdownOpen(!dropdownOpen)
                        setServiceType({ type: e.target.outerText })
                      }}
                    >
                      Дистрибутори
                    </DropdownItem>
                  </>
                }
                onDropdownClick={() => setDropdownOpen(!dropdownOpen)}

              />
              <DropdownSearch
                value={city 
                  ? city.label 
                  : district
                    ? district.label
                    : ''
                }
                description="Локация на партньора"
                image={<GpsFixedIcon />}
                text="Въведете град или ПК"
                onInputChange={e => setPlace(e)}
                onChange={(e) => {
                  if(e && Object.keys(e).indexOf('district_id') >= 0) {
                    setDistrict(e)
                    return
                  }
                  setCity(e)
                }}
              />
              <div className=" header-wrapper__search d-flex align-items-center rounded justify-content-center">
                <Link
                  className={serviceType === ''
                    ? "disabled-btn px-5 px-lg-3 header-button text-uppercase h-100 w-100 d-flex align-items-center justify-content-center shadow"
                    : "px-5 px-lg-3 header-button text-uppercase h-100 w-100 d-flex align-items-center justify-content-center shadow"
                  }
                  to={serviceType !== '' ? `/services${returnServiceUrl(serviceType)}` : '#'}
                >
                  <SearchIcon />
                  Търси
                </Link>
              </div>
            </section>
            <div className="blue-link">
              <Link
                to="/map"
                className=" justify-content-center text-center mt-4 d-flex"
              >
                Виж картата
              </Link>
            </div>
          </div>
        </Jumbotron>
        <div className='home-page-container'>
          <div className='margin-top5'>
            <Row className='home-row'>
              <Col md={12} lg={6} className='new-horizontal-card-col' >
                <Card
                  cardClass='new-horizontal-card'
                  imageClass='homepage-horizontal-card-img'
                  cardBodyClass='homepage-card-body'
                  url='/discounts'
                  btnClass='card-btn-class'
                  title="Промоционални кампании"
                  desc="Свалете ваучер за актуалните ни промоции на продукти с марките YTONG и Silka!"
                  image={ytongImage}
                />
              </Col>
              <Col md={12} lg={6} className='new-horizontal-card-col' >
                <Card
                  cardClass='new-horizontal-card'
                  cardBodyClass='homepage-card-body'
                  imageClass='homepage-horizontal-card-img'
                  url='/projects'
                  btnClass='card-btn-class'
                  title="Избрани проекти"
                  desc="Тук можете да разгледате селекция от 3D визуализации
                на авторски проекти, видени
                от различна перспектива."
                  image={archImage}
                />
              </Col>
            </Row>
          </div>
          <div className='horizontal-line-container'>
            <div className="horizontal-line" />
          </div>
          <StepsComponent data={data} />
        </div>
        <RecentNews
          staticLastProject={staticLastProject}
          staticLastNew={staticLastNew}
          staticLastArticle={staticLastArticle}
          lastData={lastData} />
      </div>
      <ConfirmationModal
        title={
          `Свалихте успешно електронния формуляр за създаване на информационен 
          профил за ${partnerType === 'architect' ? 'архитекти и архитектурни бюра' : 'строителни компании'}.
          Благодарим Ви за проявения интерес!`
        }
        isOpen={bePartnerSuccess}
        onClick={() => setBePartner()}
      />
      <ConfirmationModal
        title='Грешка. Файлът не може да бъде свален. Моля опитайте отново по-късно.'
        isOpen={bePartnerFailure}
        onClick={() => setBePartner()}
      />
    </Layout >
  );
};

const mapStateToProps = state => ({
  page: state.services.current_page,
  serviceType: state.home.serviceType,
  partnerType: state.home.partnerType,
  city: state.home.city,
  district: state.home.districtId,
  cities: state.home.cities,
  bePartnerSuccess: state.home.bePartnerSuccess,
  bePartnerFailure: state.home.bePartnerFailure
});

const mapDispatchToProps = {
  getCities,
  setServiceType,
  setCity,
  setDistrict,
  getDistricts,
  setBePartner,
  getArchitects,
  getBuilders,
  filterService,
  getLastNew,
  getLastArticle,
  getLastProject
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);

export const query = graphql`
query Steps {
  step1: file(relativePath: {eq: "step-1.png"}){
    childImageSharp {
      gatsbyImageData(
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF])
    }
  },
  step2: file(relativePath: {eq: "step-2.png"}){
    childImageSharp {
      gatsbyImageData(
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  },
  step3: file(relativePath: {eq: "step-3.png"}){
    childImageSharp {
      gatsbyImageData(
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  },
  lastArticles: allStrapiArticleslast {
    edges {
      node {
        id: strapiId
        title
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(formats: AUTO, placeholder: BLURRED)
            }
          }
        }
      }
    }
  },
  lastNews: allStrapiNewslast {
    edges {
      node {
    id: strapiId
    title
    description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(formats: AUTO, placeholder: BLURRED)
        }
      }
    }
  }
 }
},
  lastProjects: allStrapiProjectslast {
    edges {
      node {
        id: strapiId
        title
        author_name
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(formats: AUTO, placeholder: BLURRED)
            }
          }
        }
      }
    }
  },
} `



import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import "./styles.scss";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

const StepsComponent = ({ data }) => {
  const [currentStep, setStep] = useState(1);

  const stepsInfo = [
    {
      title: "",
      description:
        "Използвайте критериите за търсене, за да намерите най-подходящия партньор за Вашия обект - проектант, строител, търговец на строителни материали!",
      imageUrl: getImage(data.step1.childImageSharp)
    },
    {
      title: "",
      description:
        "Изпратете Вашите запитвания към доставчиците на услуги, на които сте се спрели, като използвате директните форми за контакт!",
      imageUrl: getImage(data.step2.childImageSharp)
    },
    {
      title: "",
      description:
        "Очаквайте техните отговори, сравнете предложенията и направете своя окончателен избор!",
      imageUrl: getImage(data.step3.childImageSharp)
    },
  ];

  return (
    <div className="steps-container mb-5 mt-3 mt-md-5">
      {stepsInfo.map((step, index) => {
        if (index + 1 === currentStep) {
          return (
            <Row key={step.title} className="flex-md-row flex-column">
              <Col className='steps-component-left-col'>
                <div>
                  <h4 className="font-weight-bold">Как работи нашата платформа?</h4>

                  <div className="d-flex justify-content-center justify-content-md-center justify-content-lg-start mb-5 steps-container__buttons">
                    {stepsInfo.map((_, i) => {
                      return (
                        <button
                          onClick={() => setStep(i + 1)}
                          className={`btn d-flex align-items-center justify-content-center mt-3 mt-md-1 mr-2 p-0 ${currentStep === i + 1 ? " active" : ""
                            }`}
                          key={i}
                        >
                          <span className="">{i + 1}</span>
                        </button>
                      );
                    })}
                  </div>

                  <h4>{step.title}</h4>
                  <p>{step.description}</p>
                </div>
              </Col>
              <Col className="steps-component-col">
                <GatsbyImage alt="" image={step.imageUrl} />
                {/* <img alt="default step" className="image" src={step.imageUrl} /> */}
              </Col>
            </Row>
          );
        }
      })}
    </div>
  );
};

export default StepsComponent;
